<template>
  <div>
    <div class="pr-2 pl-2">
      <cp-head-table
        :title="$t('privacyControl.header.title.privacyControl')"
        :button-title="$t('privacyControl.header.button.addOperator')"
        button-icon="ios-person-add"
        title-icon="ios-eye-off"
        @onCreateElem="openAddModal"
      />
    </div>
    <div>
      <div class="bg-light card-body mt-5 mr-2 ml-2 mb-1">
        <span>{{ $t("privacyControl.header.disclaimer.thisScreenAllows.line1") }}</span>
        <br>
        <span>{{ $t("privacyControl.header.disclaimer.thisScreenAllows.line2") }}</span>
      </div>
    </div>
    <cp-table
      ref="cpTable"
      get-data-action="privacyControl/getPrivacyControlOperators"
      :url-params="urlParams"
      :fields="tableFields"
    >
      <template
        slot="action"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.delete')"
          @click="openDeleteModal(rowData.item)"
        >
          <i
            class="ion ion-ios-trash"
          />
        </b-btn>
      </template>
      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        <span>{{ rowData.item.createdAt | dateFilter }}</span>
      </template>
    </cp-table>
    <cp-confirm-modal
      ref="deleteOperatorModal"
      :title="$t('privacyControl.deleteOperatorModal.title')"
      :item="operatorToDelete"
      :ok-text="$t('common.button.confirm')"
      @onOk="deleteOperator"
    >
      {{ $t('privacyControl.deleteOperatorModal.message', [operatorToDelete.email]) }}
    </cp-confirm-modal>
    <cp-general-modal
      ref="addOperatorModal"
      title-icon="ios-mail"
      :title="$t('privacyControl.addOperatorModal.title')"
      :message="$t('privacyControl.addOperatorModal.message')"
      @onOk="addOperator"
    >
      <cp-input
        v-model="model.email"
        :placeholder="$t('privacyControl.addOperatorModal.placeholder')"
        validate="required|email"
        name="email"
      />
    </cp-general-modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import tableFields from './fields';
import CpHeadTable from '~/components/common/head';
import CpTable from '~/components/shared/cp-table';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';

export default {
  name: 'PrivacyControl',
  metaInfo: {
    title: 'Privacy control',
  },
  components: {
    CpTable,
    CpHeadTable,
    CpGeneralModal,
    CpConfirmModal,
    CpInput,
  },
  data() {
    return {
      tableFields,
      urlParams: { issuerId: this.$route.params.idIssuer },
      operatorToDelete: {
        operatorId: '',
        email: '',
        externalId: '',
      },
      model: {
        email: '',
      },
    };
  },
  methods: {
    ...mapActions('privacyControl', [
      'deletePrivacyControlOperator',
      'addPrivacyControlOperator',
    ]),
    ...mapActions('issuersInfo', ['getPermissionsPerIssuer']),
    addOperator(body) {
      this.addPrivacyControlOperator({ issuerId: this.urlParams.issuerId, body })
        .then(() => {
          this.$refs.cpTable.updateTableData();
          this.$refs.addOperatorModal.hide();
        }).catch(() => {
          this.$refs.addOperatorModal.hide();
        });
    },
    openDeleteModal({ operatorId, email, externalId }) {
      this.operatorToDelete = {
        operatorId,
        email,
        externalId,
      };
      this.$refs.deleteOperatorModal.show();
    },
    openAddModal() {
      this.model.email = '';
      this.$refs.addOperatorModal.show();
    },
    deleteOperator({ operatorId }) {
      this.deletePrivacyControlOperator({ operatorId, issuerId: this.urlParams.issuerId })
        .then(() => {
          this.$refs.cpTable.updateTableData();
          this.getPermissionsPerIssuer({ issuerId: this.urlParams.issuerId });
        });
    },
  },
};
</script>
